<template>
  <b-form-group label="Upload Type:">
    <b-form-radio-group
      id="upload-type"
      v-model="uploadType"
      :options="uploadTypeOptions"
      buttons
      name="upload-type"
      @input="change"
    ></b-form-radio-group>
  </b-form-group>
</template>

<script>
export default {
  name: 'paste-upload-switch',
  data() {
    return {
      uploadType: this.$store.state.uploadType,
      uploadTypeOptions: [
        { text: 'Paste Text', value: 'paste' },
        { text: 'Upload File', value: 'upload' }
      ]
    }
  },
  methods: {
    change() {
      this.$store.dispatch('setUploadTypeAction', this.uploadType)
    }
  }
}
</script>
