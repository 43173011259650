<template>
  <div id="upload-cert">

    <PageHeader heading="Upload Certificate" />

    <b-row class="mt-2">
      <b-col cols="6">
        <b-form @submit.prevent="handleSubmit">
          <UploadTypeSwitch></UploadTypeSwitch>

          <b-form-group
            v-if="uploadType == 'paste'"
            id="certblob-upload"
            label="Paste your certificate here:"
            label-for="certblob"
            description="Ensure cert is in PEM format"
          >
            <b-form-textarea id="certblob" v-model="cert" rows="10" required></b-form-textarea>
          </b-form-group>

          <b-form-group
            v-if="uploadType == 'paste'"
            id="intermediateblob-upload"
            label="Paste your intermediate certificate here:"
            label-for="intermediateblob"
            description="Ensure cert is in PEM format"
          >
            <b-form-textarea id="intermediateblob" v-model="intermediate" rows="10" required></b-form-textarea>
          </b-form-group>

          <b-form-group v-if="uploadType == 'upload'">
            <b-form-file
              v-model="certFile"
              placeholder="Choose or drop your certificate file here"
              drop-placeholder="Drop certificate file here..."
              description="Ensure cert is in PEM format"
              required
            ></b-form-file>
          </b-form-group>

          <b-form-group v-if="uploadType == 'upload'">
            <b-form-file
              v-model="intermediateFile"
              placeholder="Choose or drop your intermediate certificate file here"
              drop-placeholder="Drop intermediate certificate file here..."
              description="Ensure cert is in PEM format"
              required
            ></b-form-file>
          </b-form-group>

          <b-button type="submit" variant="warning" v-bind:disabled="submitDisabled">Upload</b-button>
        </b-form>
      </b-col>
    </b-row>

    <b-row class="mt-2" v-if="resp">
      <b-col>
        <p>
          <strong>Upload status:</strong>
          <pre>{{ resp }}</pre>
        </p>
      </b-col>
    </b-row>

    <b-row class="mt-2" v-if="errors.length">
      <b-col>
        <p>
          <strong>Please correct the following errors:</strong>
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import CertApi from '@/services/api/Certificates'
import UploadTypeSwitch from '@/components/UploadTypeSwitch'

export default {
  name: 'upload-cert',
  components: {
    PageHeader,
    UploadTypeSwitch
  },
  data() {
    return {
      cert: '',
      intermediate: '',
      resp: null,
      errors: [],
      certFile: null,
      intermediateFile: null
    }
  },
  computed: {
    submitDisabled: function() {
      if (this.uploadType == 'paste') {
        return !(this.cert && this.intermediate)
      } else if (this.uploadType == 'upload') {
        return !(this.certFile && this.intermediateFile)
      } else {
        return true
      }
    },
    uploadType() {
      return this.$store.state.uploadType
    }
  },
  methods: {
    validateForm: function () {
      this.errors = []
      // Just doing browser validation for now, which will have already happened at this point.
      return true
    },
    handleSubmit: async function() {
      if (this.validateForm()) {

        let certContents = null
        let intermediateContents = null
        if (this.uploadType == 'paste') {
          certContents = this.cert
          intermediateContents = this.intermediate
        } else if (this.uploadType == 'upload') {
          certContents = await this.certFile.text()
          intermediateContents = await this.intermediateFile.text()

          // Strip out line endings and replace with literal \n character.
          certContents = certContents.replace(/(\r\n|\n|\r)/gm, '\n')
          intermediateContents = intermediateContents.replace(/(\r\n|\n|\r)/gm, '\n')
        }

        const resp = await CertApi.uploadCert(certContents, intermediateContents)
        if (resp.errors) {
          this.errors = this.resp.errors
        } else {
          this.resp = resp
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
